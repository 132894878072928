// components/Layout.js
import React from 'react';
import NavigationMenu from './NavigationMenu';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div>
      <NavigationMenu />
      <main>
        {children}
      </main>
      <Footer/>
    </div>
  );
}

export default Layout;
