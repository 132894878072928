import React, { useEffect, useState } from 'react';
import { ref, onValue } from "firebase/database";
import { auth, database } from '../fb';
import GridView from '../components/GridView';
import ListView from '../components/ListView';

const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const [view, setView] = useState('grid'); // Estado para alternar entre grid e list view
  const currentUser = auth.currentUser;

  useEffect(() => {
    const coursesRef = ref(database, 'courses');
    onValue(coursesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Converte o objeto de cursos em um array e filtra apenas os cursos publicados
        const coursesArray = Object.keys(data)
          .map(key => ({
            id: key,
            ...data[key]
          }))
          .filter(course => course.published === true); // Filtra apenas os cursos publicados
  
        setCourses(coursesArray);
      }
    });
  }, []);
  

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Cursos Disponíveis</h1>
      <div className="flex justify-end mb-4">
        <button 
          onClick={() => setView('grid')} 
          className={`mr-2 px-4 py-2 ${view === 'grid' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
        >
          Grelha
        </button>
        <button 
          onClick={() => setView('list')} 
          className={`px-4 py-2 ${view === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
        >
          Lista
        </button>
      </div>
      {view === 'grid' ? (
        <GridView courses={courses} userId={currentUser ? currentUser.uid : null} />
      ) : (
        <ListView courses={courses} userId={currentUser ? currentUser.uid : null} />
      )}
    </div>
  );
}

export default CoursesPage;
