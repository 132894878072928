// src/components/QuizModal.js
import React, { useState } from 'react';

const QuizModal = ({ isOpen, onClose, onSave }) => {
  const [questions, setQuestions] = useState([
    { question: '', answers: ['', '', ''], correctAnswer: 0 },
  ]);

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (qIndex, aIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answers[aIndex] = value;
    setQuestions(newQuestions);
  };

  const handleCorrectAnswerChange = (qIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].correctAnswer = parseInt(value);
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: '', answers: ['', '', ''], correctAnswer: 0 }]);
  };

  const handleSave = () => {
    onSave(questions);
    onClose();
  };

  return (
      <div className="min-h-screen px-4 text-center">

        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
          {questions.map((q, qIndex) => (
            <div key={qIndex} className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">Pergunta</label>
              <input
                type="text"
                value={q.question}
                onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              />
              <label className="block text-gray-700 font-bold mb-2">Respostas</label>
              {q.answers.map((answer, aIndex) => (
                <input
                  key={aIndex}
                  type="text"
                  value={answer}
                  onChange={(e) => handleAnswerChange(qIndex, aIndex, e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded mb-2"
                />
              ))}
              <label className="block text-gray-700 font-bold mb-2">Resposta Correta</label>
              <input
                type="number"
                value={q.correctAnswer}
                onChange={(e) => handleCorrectAnswerChange(qIndex, e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          ))}
          <button onClick={handleAddQuestion} className="bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2">
            Adicionar Pergunta
          </button>
          <button onClick={handleSave} className="bg-green-600 text-white font-bold py-2 px-4 rounded mr-2">
            Salvar
          </button>
          <button onClick={onClose} className="bg-gray-600 text-white font-bold py-2 px-4 rounded">
            Cancelar
          </button>
        </div>
      </div>
  );
};

export default QuizModal;
