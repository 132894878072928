import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ref, onValue, set, remove } from "firebase/database";
import { database } from '../../fb';

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [publishingCourseId, setPublishingCourseId] = useState(null);
  const [deletingCourseId, setDeletingCourseId] = useState(null);

  useEffect(() => {
    const coursesRef = ref(database, 'courses');
    onValue(coursesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const loadedCourses = Object.keys(data).map((key) => ({
          id: key,
          ...data[key]
        }));
        setCourses(loadedCourses);
        console.log(loadedCourses)
      }
    });
  }, []);

  const handlePublish = (courseId) => {
    setPublishingCourseId(courseId);
  };

  const confirmPublish = async (courseId) => {
    const courseRef = ref(database, `courses/${courseId}`);
    try {
      await set(courseRef, { ...courses.find(course => course.id === courseId), published: true });
      setPublishingCourseId(null);
      setCourses(courses.map(course => course.id === courseId ? { ...course, published: true } : course));
      console.log('Course published successfully:', courseId);
    } catch (error) {
      console.error('Error publishing course:', error);
    }
  };

  const handleDelete = (courseId) => {
    setDeletingCourseId(courseId);
  };

  const confirmDelete = async (courseId) => {
    const courseRef = ref(database, `courses/${courseId}`);
    try {
      await remove(courseRef);
      setDeletingCourseId(null);
      setCourses(courses.filter(course => course.id !== courseId));
      console.log('Course deleted successfully:', courseId);
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  return (
    <div className="container mx-auto p-6 bg-white dark:bg-gray-900">
      <h1 className="text-3xl font-semibold mb-6 dark:text-white">My Courses</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {courses.map((course) => (
          <div key={course.id} className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
            <img src={course.coverImage || 'https://via.placeholder.com/150'} alt={course.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2 dark">{course.title}</h2>
              <p className="text-gray-700 dark:text-dark-300 mb-4">{course.description}</p>
              <div className="flex justify-between items-center">
                <span className="text-lg font-bold text-gray-900 dark">{course.price || 'Free'} MT</span>
                <span className={`px-2 py-1 rounded ${course.published ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}>
                  {course.published ? 'Published' : 'Draft'}
                </span>
              </div>
              <div className="mt-4 flex justify-between">
                <Link to={`/edit-course/${course.id}`} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">ENTRAR</Link>
                <button onClick={() => handlePublish(course.id)} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">PUBLICAR</button>
                <button onClick={() => handleDelete(course.id)} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">ELIMINAR</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {publishingCourseId && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-semibold mb-4 dark:text-white">Confirm Publish</h2>
            <p className="mb-4">Are you sure you want to publish this course?</p>
            <div className="flex justify-end">
              <button onClick={() => setPublishingCourseId(null)} className="mr-2 px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500">Cancel</button>
              <button onClick={() => confirmPublish(publishingCourseId)} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">Publish</button>
            </div>
          </div>
        </div>
      )}
      {deletingCourseId && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-semibold mb-4 dark:text-white">Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this course?</p>
            <div className="flex justify-end">
              <button onClick={() => setDeletingCourseId(null)} className="mr-2 px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500">Cancel</button>
              <button onClick={() => confirmDelete(deletingCourseId)} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseList;
