import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../fb';
import { ref as dbRef, update } from 'firebase/database';
import { useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar'; 
import MuiAlert from '@mui/material/Alert'; 

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UploadMedia = () => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [videoDetails, setVideoDetails] = useState([]);
  const [moduleTitle, setModuleTitle] = useState('');
  const [lessonTitle, setLessonTitle] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false); 
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [uploadDisabled, setUploadDisabled] = useState(false); 

  const handleFilesChange = (e) => {
    setFiles([...e.target.files]);
  };

  const sanitizeFileName = (fileName) => {
    const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, '');
    const sanitizedFileName = fileNameWithoutExtension.replace(/[^a-zA-Z0-9 ]/g, ' ');
    return sanitizedFileName;
  };

  const handleUpload = () => {
    if (!moduleTitle  || files.length === 0) {
      setSnackbarMessage('Preencha todos os campos e selecione pelo menos um arquivo.');
      setOpenSnackbar(true);
      return;
    }

    setUploadDisabled(true);
    const details = [];

    files.forEach((file, index) => {
      const sanitizedFileName = sanitizeFileName(file.name);
      const storageRef = ref(storage, `videos/${sanitizedFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: progress,
          }));
        },
        (error) => {
          console.error('Upload failed:', error);
          setSnackbarMessage('Falha no upload. Tente novamente.');
          setOpenSnackbar(true);
          setUploadDisabled(false);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const video = document.createElement('video');
            video.src = downloadURL;
            video.onloadedmetadata = () => {
              const duration = video.duration;
              const videoData = { name: sanitizedFileName, url: downloadURL, duration, title: lessonTitle, type: 'video' };
              details.push(videoData);

              const lessonRef = dbRef(database, `courses/${id}/modules/${sanitizeFileName(moduleTitle)}/lessons/${sanitizedFileName}`);
              update(lessonRef, videoData);

              if (index === files.length - 1) {
                const moduleRef = dbRef(database, `courses/${id}/modules/${sanitizeFileName(moduleTitle)}`);
                update(moduleRef, { title: moduleTitle });
                setVideoDetails(details);
                setSnackbarMessage('Upload realizado com sucesso!');
                window.location='edit-course/'+id
                setOpenSnackbar(true);
              }
            };
          } catch (error) {
            console.error('Error getting download URL:', error);
            setSnackbarMessage('Erro ao obter URL de download. Tente novamente.');
            setOpenSnackbar(true);
            setUploadDisabled(false);
          }
        }
      );
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Carrgar de vídeo aulas</h2>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="moduleTitle">
          Título do Módulo
        </label>
        <input
          type="text"
          id="moduleTitle"
          value={moduleTitle}
          onChange={(e) => setModuleTitle(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          placeholder="Digite o título do módulo"
        />
      </div>
      <input
        type="file"
        multiple
        onChange={handleFilesChange}
        className="mb-4"
        disabled={uploadDisabled}
      />
      <button
        onClick={handleUpload}
        className="bg-blue-600 text-white font-bold py-2 px-4 rounded"
        disabled={uploadDisabled} 
      >
        Carregar
      </button>
      <div className="mt-4">
        {files.map((file) => (
          <div key={file.name} className="mb-2">
            <span>{file.name}</span>
            <div className="w-full bg-gray-200 rounded h-4 mt-2">
              <div
                className="bg-blue-600 h-4 rounded"
                style={{ width: `${uploadProgress[file.name] || 0}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarMessage.includes('sucesso') ? 'success' : 'error'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UploadMedia;
