import React, { useState } from 'react';
import CertificateGenerator from '../components/CertificateGenerator';

const Certificate = () => {
  const [name, setName] = useState('Junior Aurélio');
  const [course, setCourse] = useState('Monitoria e Avaliação de Projectos');
  const [date, setDate] = useState('12 de Julho de 2024');
  const [issuer, setIssuer] = useState('Manuel Junior');

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Gerador de Certificados</h1>
      <input
        type="text"
        placeholder="Nome"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{ margin: '10px', padding: '5px' }}
      />
      <input
        type="text"
        placeholder="Curso"
        value={course}
        onChange={(e) => setCourse(e.target.value)}
        style={{ margin: '10px', padding: '5px' }}
      />
      <input
        type="text"
        placeholder="Data"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        style={{ margin: '10px', padding: '5px' }}
      />
      <input
        type="text"
        placeholder="Provido por"
        value={issuer}
        onChange={(e) => setIssuer(e.target.value)}
        style={{ margin: '10px', padding: '5px' }}
      />
      <CertificateGenerator name={name} course={course} date={date} issuer={issuer} />
    </div>
  );
};

export default Certificate;
