import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ref, get, set } from 'firebase/database';
import { auth, database } from '../fb';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Typography, Container, Paper } from '@mui/material';  // You still need MUI for Dialog component

const ModuleQuiz = () => {
  const { courseId, moduleName } = useParams();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [feedback, setFeedback] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);  // State to handle dialog open/close

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        setLoading(false);
      } else {
       window.location='/login';
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (courseId && moduleName) {
      const moduleQuizRef = ref(database, `courses/${courseId}/modules/${moduleName}/quiz/questions`);
      get(moduleQuizRef).then((snapshot) => {
        if (snapshot.exists()) {
          setQuestions(snapshot.val());
        }
      });
    }
  }, [courseId, moduleName]);

  const handleChange = (event, questionIndex) => {
    setAnswers({
      ...answers,
      [questionIndex]: event.target.value,
    });
  };

  const handleSubmit = () => {
    let correctAnswersCount = 0;
    questions.forEach((question, index) => {
      if (answers[index] == question.correctAnswer) {
        correctAnswersCount += 1;
      }
    });

    const score = (correctAnswersCount / questions.length) * 100;
    setScore(score);

    setFeedback(`Você acertou ${correctAnswersCount} de ${questions.length} perguntas. Sua pontuação: ${score}%`);

    if (userId) {
      const userModuleRef = ref(database, `users/${userId}/courses/${courseId}/modules/${moduleName}/quiz`);
      set(userModuleRef, {
        score,
        completed: true,
      });
    }
  };

  const handleConfirmSubmit = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = () => {
    handleSubmit();
    setOpenDialog(false);
  };

  if (loading) {
    return <Typography variant="body1">Carregando...</Typography>;
  }

  return (
    <Container className="p-4 max-w-3xl mx-auto">
      <Paper className="p-6 shadow-md">
        <Typography variant="h4" className="mb-4">
          Módulo: {moduleName}
        </Typography>
        {questions.length > 0 ? (
          <form>
            {questions.map((question, index) => (
              <div key={index} className="mb-6">
                <FormControl component="fieldset" className="mb-4">
                  <FormLabel component="legend" className="text-lg font-semibold">{question.question}</FormLabel>
                  <RadioGroup
                    aria-label={question.question}
                    name={`question-${index}`}
                    value={answers[index] || ''}
                    onChange={(event) => handleChange(event, index)}
                    className="mt-2"
                  >
                    {question.answers.map((answer, i) => (
                      <FormControlLabel key={i} value={i} control={<Radio />} label={answer} />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            ))}
            <Button variant="contained" color="primary" onClick={handleConfirmSubmit} className="mt-4">
              Enviar Respostas
            </Button>
          </form>
        ) : (
          <Typography variant="body1">Carregando perguntas...</Typography>
        )}
        {feedback && (
          <div className="mt-6">
            <Typography variant="h6" className="text-blue-500">
              {feedback}
            </Typography>
            {score >= 70 ? (
              <Button variant="contained" color="primary" onClick={() => window.location=`/play/${courseId}`} className="mt-4">
               Regressar as aulas
              </Button>
            ) : (
              <Button variant="contained" color="secondary" onClick={() => window.location.reload()} className="mt-4">
                Reiniciar
              </Button>
            )}
          </div>
        )}
      </Paper>

      {/* Dialog component for confirmation */}
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle>Confirmação</DialogTitle>
        <DialogContent>
          <Typography>Você tem certeza de que deseja enviar o quiz?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDialogConfirm} color="secondary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ModuleQuiz;
