import React, { useState, useEffect } from 'react';
import CourseList from '../instrutor/CoursesList';
import { Link } from 'react-router-dom';

const AdminCourses = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const authenticated = localStorage.getItem('isAuthenticated');
    if (authenticated) {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'Racibo99') {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
      setError('');
    } else {
      setError('Senha incorreta. Tente novamente.');
    }
  }

  return (
    <div>
      {!isAuthenticated ? (
        <div className="p-4">
          <h1>Digite a senha para acessar</h1>
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="px-4 py-2 border rounded"
              placeholder="Senha"
            />
            {error && <p className="text-red-500">{error}</p>}
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Entrar
            </button>
          </form>
        </div>
      ) : (
        <div>
          <h1>
            Manage Courses&nbsp;&nbsp; 
            <Link to={`/new`} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Novo curso</Link>
          </h1>
          <CourseList />
        </div>
      )}
    </div>
  );
};

export default AdminCourses;
