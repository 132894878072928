import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import Upload from '../components/Upload';
import { ref, push, set } from "firebase/database";
import { database } from '../fb';

const CreateCourse = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [price, setPrice] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCoverImage(file);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleUpload = async () => {
    if (validateForm()) {
      setLoading(true);
      if (coverImage) {
        try {
          const downloadURL = await Upload('image', coverImage, 'course_covers');
          await saveCourseData(downloadURL);
        } catch (error) {
          showSnackbar('Falha ao publicar video', 'error');
          setLoading(false);
        }
      } else {
        await saveCourseData(null);
      }
    }
  };

  const saveCourseData = async (downloadURL) => {
    const newCourseRef = push(ref(database, 'courses'));
    const courseId = newCourseRef.key;

    const courseData = {
      id: courseId,
      title,
      description,
      coverImage: downloadURL,
      price,
      published: false,
      sections: [],
    };

    try {
      await set(newCourseRef, courseData);
      showSnackbar('Curso cadastrado', 'Feito');
      clearForm();
      setTimeout(() => {
        navigate('/courseslist'); 
      }, 2000); 
    } catch (error) {
      showSnackbar('Falha ao cadatrar', 'erro');
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (!title || !description || !price) {
      showSnackbar('Preencha todos os campos', 'Aviso');
      return false;
    }
    if (isNaN(price) || parseFloat(price) <= 0) {
      showSnackbar('Insira um valor valido', 'Aviso');
      return false;
    }
    return true;
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: '' });
  };

  const clearForm = () => {
    setTitle('');
    setDescription('');
    setCoverImage(null);
    setPreviewImage(null);
    setPrice('');
  };

  return (
    <div className="container mx-auto p-6 bg-white dark:bg-gray-900">
      <h1 className="text-3xl font-semibold mb-6 dark:text-white">Novo curso</h1>
      
      <div className="mb-6">
        <label className="block text-dark-700 dark:text-dark-300 font-medium mb-2">Nome do curso</label>
        <input 
          type="text" 
          className="w-full p-2 border border-gray-300 dark:border-dark-700 rounded dark:bg-gray-800 dark:text-white" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
          placeholder="Titulo ou Nome do curso" 
        />
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 dark:text-dark-300 font-medium mb-2">Descricao</label>
        <textarea 
          className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-800 dark:text-white" 
          rows="4" 
          value={description} 
          onChange={(e) => setDescription(e.target.value)} 
          placeholder="Texto breve que fale um pouco sobre o curso"
        ></textarea>
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 dark:text-dark-300 font-medium mb-2">Foto de capa</label>
        <input 
          type="file" 
          className="w-full p-2 border border-gray-300 dark:border-dark-700 rounded dark:bg-gray-800 dark:text-white" 
          onChange={handleImageChange} 
        />
        {previewImage && <img src={previewImage} alt="Cover Preview" className="mt-4 max-w-xs" />}
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 dark:text-dark-300 font-medium mb-2">Preco</label>
        <input 
          type="text" 
          className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-800 dark:text-white" 
          value={price} 
          onChange={(e) => setPrice(e.target.value)} 
          placeholder="$" 
        />
      </div>
      <div className="flex space-x-4">
        <button onClick={handleUpload} className="p-2 bg-green-500 text-white rounded" disabled={loading}>
          {loading ? 'A cadastrar...' : 'Cadastrar'}
        </button>
      </div>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateCourse;
