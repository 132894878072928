import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CoursesPage from './pages/CoursesPage';
import CourseDetailPage from './pages/CourseDetailPage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import Layout from './components/Layout.jsx';
import CreateCourse from './pages/CreateCourse.jsx';
import CourseList from './pages/instrutor/CoursesList.jsx';
import AddLesson from './pages/instrutor/AddLesson.jsx';
import UploadMedia from './components/UploadMedia.js';
import Player from './pages/Player.jsx';
import './app.css'
import CoursePlayer from './pages/CoursePlayer.jsx';
import CreateQuiz from './components/CreateQuiz.js';
import MyQuiz from './components/MyQuiz.js';
import ViewCourse from './pages/ViewCourse.jsx';
import MyCoursesPage from './pages/MyCoursesPage.jsx';
import Profile from './pages/Profile.jsx';
import Certificate from './pages/Certificate.jsx';
import AdminLayout from './components/AdminLayout.jsx';
import AdminCourses from './pages/admin/AdminCourses.jsx';
import Payment from './pages/Payment.jsx';
import ModuleQuiz from './components/ModuleQuiz .jsx';
import CheckoutPage from './pages/Payment.jsx';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><HomePage /></Layout>} />
        <Route path="/courses" element={<Layout><CoursesPage /></Layout>} />
        <Route path="/mycourses" element={<Layout><MyCoursesPage /></Layout>} />
        <Route path="/course/:id" element={<Layout><CourseDetailPage /></Layout>} />
        <Route path="/register" element={<Layout><RegisterPage /></Layout>} />
        <Route path="/login" element={<Layout><LoginPage /></Layout>} />
        <Route path="/profile" element={<Layout><Profile /></Layout>} />
        <Route path="/new" element={<Layout><CreateCourse /></Layout>} />
        <Route path="/coursesList" element={<Layout><CourseList /></Layout>} />
        <Route path="/viewcourse/:id" element={<Layout><ViewCourse /></Layout>} />
        <Route path="/play/:id" element={<Layout><CoursePlayer /></Layout>} />
        <Route path="/modulequiz/:courseId/:moduleName"  element={<Layout><ModuleQuiz /></Layout>} />
        <Route path="/myquiz/:id" element={<Layout><MyQuiz /></Layout>} />
        <Route path="/certificate" element={<Layout><Certificate /></Layout>} />
        <Route path="/checkout/:id" element={<Layout><CheckoutPage /></Layout>} />

        <Route path="/admin/" element={<AdminLayout><AdminCourses /></AdminLayout>} />
        <Route path="/upload/:id" element={<AdminLayout><UploadMedia /></AdminLayout>} />
        <Route path="/quiz/:id" element={<AdminLayout><CreateQuiz /></AdminLayout>} />
        <Route path="/edit-course/:id" element={<AdminLayout><AddLesson /></AdminLayout>} />
      </Routes>
    </Router>
  );
}
export default App;
