import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ref, set, onValue, push,  update, remove } from 'firebase/database';
import Upload from '../../components/Upload';
import { database } from '../../fb';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Quiz, VideoLibrary } from '@mui/icons-material';
import { Modal, Box, TextField, Button, Tabs, Tab } from '@mui/material';
import EditorQuill from '../../utils/editor';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UploadTrailer = () => {
  const { id } = useParams();
  const [videoFile, setVideoFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [trailerUrl, setTrailerUrl] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [requisitos, setRequisitos] = useState('');
  const [courseRequirements, setCourseRequirements] = useState([]);
  const [modules, setModules] = useState({});
  const [students, setStudents] = useState({});
  const [reviews, setReviews] = useState({});
  const [selectedModule, setSelectedModule] = useState(null);
  const [openModuleModal, setOpenModuleModal] = useState(false);
  const [textContent, setTextContent] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [course, setCourse] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [published, setPublished] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);

  const quillRef = useRef(null);

  useEffect(() => {
    const trailerRef = ref(database, `courses/${id}/trailer`);
    onValue(trailerRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setTrailerUrl(data);
      }
          // Busca os dados do curso do Firebase
    const courseRef = ref(database, `courses/${id}`);
    onValue(courseRef, (snapshot) => {
      const data = snapshot.val();
      setCourse(data);
      setTitle(data.title);
      setDescription(data.description);
      setPrice(data.price);
      setCoverImage(data.coverImage);
      setPublished(data.published);
    });
    });
    

    const requisitosRef = ref(database, `courses/${id}/requisitos`);
    onValue(requisitosRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCourseRequirements(data);
      }
    });

    const studantsRef = ref(database, `courses/${id}/students`);
    onValue(studantsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setStudents(data);
      }
    });
    const reviewsRef = ref(database, `courses/${id}/reviews`);
    onValue(reviewsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setReviews(data);
      }
    });

    const modulesRef = ref(database, `courses/${id}/modules`);
    onValue(modulesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setModules(data);
      }
    });
  }, [id]);

  const handleVideoChange = (file) => {
    setVideoFile(file);
  };

  const handleUploadTrailer = async () => {
    if (trailerUrl) {
      setOpenSnackbar(true);
      return;
    }
    try {
      let downloadURL = '';
      if (videoFile) {
        downloadURL = await Upload('video', videoFile, 'course_trailers');
      }

      const trailerRef = ref(database, `courses/${id}/trailer`);
      await set(trailerRef, downloadURL || videoUrl);
      setTrailerUrl(downloadURL || videoUrl);
      setVideoUrl('');
      setVideoFile(null);
      setOpenSnackbar(true);
      setTimeout(() => {
        window.location = `/course/${id}`;
      }, 2000);
      console.log('Trailer uploaded successfully:', downloadURL || videoUrl);
    } catch (error) {
      console.error('Error uploading trailer:', error);
    }
  };

  const handleAddRequisito = () => {
    const requisitosRef = ref(database, `courses/${id}/requisitos`);
    const updatedRequirements = [...courseRequirements, requisitos];
    set(requisitosRef, updatedRequirements);
    setCourseRequirements(updatedRequirements);
    setRequisitos('');
    setOpenModal(false);
  };

  const handleModuleClick = (moduleKey) => {
    setSelectedModule(modules[moduleKey]);
    setOpenModuleModal(true);
  };

  const handleAddTextContent = () => {
    if (selectedModule && textContent) {
      const moduleRef = ref(database, `courses/${id}/modules/${selectedModule.title}/aulaTexto`);
  
      set(moduleRef, textContent); 
      
      setTextContent('');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const handleSave = () => {
    const courseRef = ref(database, `courses/${id}`);
    update(courseRef, { title, description, price, coverImage });
    setEditMode(false);
  };

  const handleDelete = () => {
    const courseRef = ref(database, `courses/${id}`);
    remove(courseRef);
    window.location = 'coursesList';
  };

  const handleCancelPublication = () => {
    const courseRef = ref(database, `courses/${id}`);
    update(courseRef, { published: false });
    setPublished(false);
  };
  const handlePublish = () => {
    const courseRef = ref(database, `courses/${id}`);
    update(courseRef, { published: true });
    setPublished(true);
  };

  const handleAddQuizToModule = (moduleKey) => {
    setSelectedModule(moduleKey);
    setQuizModalOpen(true);
  };

  const handleSaveQuiz = (quiz) => {
    const moduleRef = ref(database, `courses/${id}/modules/${selectedModule}/quiz`);
    update(moduleRef, { questions: quiz });
  };
  return (
    <div className="container mx-auto p-6 bg-white">
      {editMode ? (
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="title">
              Título
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="description">
              Descrição
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="price">
              Preço
            </label>
            <input
              type="text"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4" style={{display:'none'}}>
            <label className="block text-gray-700 font-bold mb-2" htmlFor="coverImage">
              Imagem de Capa
            </label>
            <input
              type="text"
              id="coverImage"
              value={coverImage}
              onChange={(e) => setCoverImage(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <button
            onClick={handleSave}
            className="bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Salvar
          </button>
          <button
            onClick={() => setEditMode(false)}
            className="bg-gray-600 text-white font-bold py-2 px-4 rounded"
          >
            Cancelar
          </button>
        </div>
      ) : (
      <>
      <h1 className="text-3xl font-semibold mb-6 text-black">{title}</h1>
      <button
            onClick={() => setEditMode(true)}
            className="bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Editar
          </button>
          <button
            onClick={handleDelete}
            className="bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Eliminar
          </button>
          {published ? (
                <button
                  onClick={handleCancelPublication}
                  className="bg-yellow-600 text-white font-bold py-2 px-4 rounded hover:bg-yellow-700 transition-colors"
                >
                  Cancelar Publicação
                </button>
              ) : (
                <button
                  onClick={handlePublish}
                  className="bg-yellow-600 text-white font-bold py-2 px-4 rounded hover:bg-yellow-700 transition-colors"
                >
                  Publicar
                </button>
              )}
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="tabs">
        <Tab label="Upload Trailer" />
        <Tab label="Requisitos do Curso" />
        <Tab label="Módulos do Curso" />
        <Tab label="Inscritos" />
        <Tab label="Exame" />
        <Tab label="Reviews" />
      </Tabs>
      <div hidden={tabIndex !== 0}>
        <div className="mb-6 mt-4">
          <label className="block text-black font-medium mb-2">Trailer Upload</label>
          <input
            type="file"
            className="w-full p-2 border border-gray-300 rounded bg-white text-black"
            onChange={(e) => handleVideoChange(e.target.files[0])}
            disabled={trailerUrl !== ''}
          />
        </div>
        <button
          onClick={handleUploadTrailer}
          className="p-2 bg-blue-500 text-white rounded"
          disabled={trailerUrl !== ''}
        >
          Upload Trailer
        </button>
        <div className="mt-6">
          <h2 className="text-xl font-semibold mb-4 text-black">Vídeo de Introdução</h2>
          {trailerUrl ? (
            <div>
              <video controls style={{ width: '100%' }}>
                <source src={trailerUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p className="text-black"><a href={trailerUrl}>Link do vídeo</a></p>
            </div>
          ) : (
            <p className="text-black">Nenhum vídeo de introdução carregado.</p>
          )}
        </div>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
          <Alert onClose={() => setOpenSnackbar(false)} severity="warning">
            Você já fez o upload do vídeo!
          </Alert>
        </Snackbar>
      </div>
      <div hidden={tabIndex !== 1}>
        <h2 className="text-xl font-semibold mb-4 text-black">Requisitos do Curso</h2>
        <button onClick={() => setOpenModal(true)} className="p-2 bg-blue-500 text-white rounded">Adicionar Requisito</button>
        <ul className="list-disc pl-5 mt-4 text-black">
          {courseRequirements.map((req, index) => (
            <li key={index}>{req}</li>
          ))}
        </ul>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box className="p-6 bg-white rounded shadow-lg mx-auto my-4" style={{ width: '300px' }}>
            <h2 id="modal-title" className="text-lg font-medium mb-4">Adicionar Requisito</h2>
            <TextField
              id="requisito"
              label="Requisito"
              value={requisitos}
              onChange={(e) => setRequisitos(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"/>
            <Button onClick={handleAddRequisito} variant="contained" color="primary">Adicionar</Button>
          </Box>
        </Modal>
      </div>
      <div hidden={tabIndex !== 2}>
        <h2 className="text-xl font-semibold mb-4 text-black">Módulos do Curso </h2>
        <a href={`/upload/${id}`} className="p-2 bg-blue-500 text-white rounded">Adicionar Modulo</a>
        {Object.keys(modules).length > 0 ? (
          <ul className="list-disc pl-5 mt-4 text-black">
            {Object.keys(modules).map((key) => (
              <li key={key} onClick={() => handleModuleClick(key)}>{modules[key].title}</li>
            ))}
          </ul>
        ) : (
          <p className="text-black">Nenhum módulo adicionado.</p>
        )}
        <Modal
          open={openModuleModal}
          onClose={() => setOpenModuleModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box className="p-6 bg-white rounded shadow-lg mx-auto my-4" style={{ width: '600px' }}>
            <h2 id="modal-title" className="text-lg font-medium mb-4">Adicionar Conteúdo ao Módulo</h2>
              <EditorQuill
          ref={quillRef}
          value={textContent}
          onChange={(value) => setTextContent(value)}
        />
            <Button onClick={handleAddTextContent} variant="contained" color="primary">Adicionar Conteúdo</Button>
          </Box>
        </Modal>
      </div>
      <div hidden={tabIndex !== 3}>
        <h2 className="text-xl font-semibold mb-4 text-black">Alunos Inscritos</h2>
        {Object.keys(students).length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 text-center">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Nome</th>
                  <th className="py-2 px-4 border-b">Telefone</th>
                  <th className="py-2 px-4 border-b">Email</th>
                  <th className="py-2 px-4 border-b">Método de Pagamento</th>
                  <th className="py-2 px-4 border-b">Data de Inscrição</th>
                  <th className="py-2 px-4 border-b">Certificado</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(students).map((key) => (
                  <tr key={key} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{students[key].name}</td>
                    <td className="py-2 px-4 border-b">{students[key].phone}</td>
                    <td className="py-2 px-4 border-b">{students[key].email}</td>
                    <td className="py-2 px-4 border-b">{students[key].paymentMethod}</td>
                    <td className="py-2 px-4 border-b">{students[key].subscriptionDate}</td>
                    <td className="py-2 px-4 border-b">{students[key]?.finish}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-black">Nenhum aluno inscrito.</p>
        )}
      </div>
      <div hidden={tabIndex !== 4}>
  <h2 className="text-xl font-semibold mb-4 text-black">Exame</h2>
  <div>
    <h3 className="text-xl font-bold">Perguntas</h3>
    {course?.quiz && course.quiz.questions.map((question, index) => (
      <div key={index} className="mb-6">
        <p className="font-semibold mb-2">{question.question}</p>
        <ul className="list-disc pl-5">
          {question.answers.map((answer, i) => (
            <li 
              key={i} 
              className={`${i === question.correctAnswer ? 'text-green-600 font-bold' : ''}`}>
              {answer}
            </li>
          ))}
        </ul>
      </div>
    ))}
    <button 
      className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors"
    >
      Editar Quiz
    </button>
  </div>
</div>
      <div hidden={tabIndex !== 5}>
        <h2 className="text-xl font-semibold mb-4 text-black">Reviews</h2>
        {Object.keys(reviews).length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300 text-center">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b">Aluno</th>
                  <th className="py-2 px-4 border-b">Rating</th>
                  <th className="py-2 px-4 border-b">Comentario</th>
                
                </tr>
              </thead>
              <tbody>
                {Object.keys(reviews).map((key) => (
                  <tr key={key} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{reviews[key].userName}</td>
                    <td className="py-2 px-4 border-b">{reviews[key].rating}</td>
                    <td className="py-2 px-4 border-b">{reviews[key].comment}</td>
                 
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-black">Nenhum aluno inscrito.</p>
        )}
      </div>
      </>
        )}
    </div>
  );
};

export default UploadTrailer;
