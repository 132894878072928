// components/GridView.js
import React from 'react';
import CourseCard from './CourseCard';

const GridView = ({ courses, userId }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {courses.map((course) => (
        <CourseCard key={course.id} course={course} userId={userId} />
      ))}
    </div>
  );
}

export default GridView;