import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import {getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDMOcCQIr-EBw0pAeivQ6xVMjlgrk7870g",
  authDomain: "mjacademy-a3987.firebaseapp.com",
  projectId: "mjacademy-a3987",
  storageBucket: "mjacademy-a3987.appspot.com",
  messagingSenderId: "668108433573",
  appId: "1:668108433573:web:d2a9482ce79c7408d3c54c",
  measurementId: "G-27V564J3CD"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const database = getDatabase();
const storage = getStorage()

export { auth, googleProvider, database, storage };