import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from '../images/logo.png';

const CertificateGenerator = ({ name, course, date, issuer }) => {
  const certificateRef = useRef();

  const generatePDF = () => {
    html2canvas(certificateRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('landscape');
      pdf.addImage(imgData, 'PNG', 0, 0, 297, 210);
      pdf.save(`${name}-certificate.pdf`);
    });
  };

  return (
    <div className="flex flex-col items-center p-4 bg-gray-100">
      <div ref={certificateRef} className="bg-white shadow-lg p-8 w-full max-w-2xl">
        <div className="text-center">
          <img src={logo} alt="Logo" className="mx-auto mb-4 w-1/5" />
          <h2 className="text-2xl font-bold mb-6">CERTIFICADO DE CONCLUSÃO</h2>
        </div>
        <div className="text-center mb-8">
          <p className="text-lg mb-4">
            É com enorme honra que reconhecemos a participação na capacitação em <b>{course}</b>, tendo concluído todas as unidades temáticas
          </p>
          <h3 className="text-2xl italic mb-4">{name}</h3>
          <p className="text-lg mb-2">Curso: {course}</p>
          <p className="text-lg mb-2">Nampula, aos {date}</p>
          <p className="text-lg mb-2">Provido por: {issuer}</p>
        </div>
        <div className="text-center">
          <p className="underline font-semibold">Manuel Junior</p>
        </div>
      </div>
      <button
        onClick={generatePDF}
        className="mt-6 bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition-colors"
      >
        Baixar Certificado
      </button>
    </div>
  );
};

export default CertificateGenerator;
