import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Typography, Box, LinearProgress, Button, Container, Paper, TextField, Rating, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ref, set, get, push, update, remove } from 'firebase/database';
import { auth, database } from '../fb';
import ReactPlayer from 'react-player';

const Player = ({ course, currentLessonIndex }) => {
  const [currentLesson, setCurrentLesson] = useState(null);
  const [lessonsCompleted, setLessonsCompleted] = useState({});
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [courseCompleted, setCourseCompleted] = useState(false);
  const [currentModule, setCurrentModule] = useState(null);
  const [moduleQuizAvailable, setModuleQuizAvailable] = useState(false);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [reviews, setReviews] = useState([]);
  const [editReview, setEditReview] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        setLoading(false);
        setUser(user);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (currentLessonIndex && course.modules) {
      const { moduleId, lesson } = currentLessonIndex;
      setCurrentModule(course.modules[moduleId]);
      setCurrentLesson(lesson);
    } else if (course.modules && Object.keys(course.modules).length > 0) {
      const firstModule = course.modules[Object.keys(course.modules)[0]];
      const firstLesson = firstModule.lessons[Object.keys(firstModule.lessons)[0]];
      setCurrentModule(firstModule);
      setCurrentLesson(firstLesson);
    }
  }, [currentLessonIndex, course.modules]);

  useEffect(() => {
    if (userId && course.id) {
      const userCourseRef = ref(database, `users/${userId}/courses/${course.id}/lessons`);
      get(userCourseRef).then((snapshot) => {
        if (snapshot.exists()) {
          setLessonsCompleted(snapshot.val());
        }
      });
    }
  }, [userId, course.id]);

  useEffect(() => {
    if (userId && course.id) {
      const userCourseRef = ref(database, `users/${userId}/courses/${course.id}/progress`);
      get(userCourseRef).then((snapshot) => {
        if (snapshot.exists()) {
          setProgress(snapshot.val());
          checkCourseCompletion(snapshot.val());
        }
      });
    }
  }, [userId, course.id]);

  useEffect(() => {
    if (course.id) {
      const reviewsRef = ref(database, `courses/${course.id}/reviews`);
      get(reviewsRef).then((snapshot) => {
        if (snapshot.exists()) {
          const reviewsData = snapshot.val();
          const reviewsArray = Object.entries(reviewsData).map(([id, review]) => ({ id, ...review })); // Convert object to array with id
          setReviews(reviewsArray);
        }
      });
    }
  }, [course.id]);

  const handleLessonCompleted = async () => {
    if (!userId || !currentLesson) return;

    const lessonId = encodeURIComponent(currentLesson.name);
    setLessonsCompleted((prevCompleted) => {
      const updatedCompleted = { ...prevCompleted, [lessonId]: true };
      
      const userLessonsRef = ref(database, `users/${userId}/courses/${course.id}/lessons`);
      set(userLessonsRef, updatedCompleted);

      const totalLessons = Object.keys(course.modules).reduce((acc, moduleId) => {
        return acc + Object.keys(course.modules[moduleId].lessons).length;
      }, 0);
      const completedLessons = Object.keys(updatedCompleted).length;
      const newProgress = (completedLessons / totalLessons) * 100;
      
      const userProgressRef = ref(database, `users/${userId}/courses/${course.id}/progress`);
      set(userProgressRef, newProgress);

      setProgress(newProgress);
      checkCourseCompletion(newProgress);

      checkModuleCompletion(currentModule, updatedCompleted);

      findNextLesson();

      return updatedCompleted;
    });
  };

  const checkModuleCompletion = (module, completedLessons) => {
    const allLessonsCompleted = Object.keys(module.lessons).every((lessonId) => {
      const lessonName = module.lessons[lessonId].name;
      const encodedLessonName = encodeURIComponent(lessonName);
      return completedLessons[encodedLessonName];
    });

    setModuleQuizAvailable(allLessonsCompleted);
  };

  const checkCourseCompletion = (newProgress) => {
    if (newProgress === 100) {
      setCourseCompleted(true);
    }
  };

  const findNextLesson = () => {
    let foundCurrent = false;
    let nextLesson = null;

    for (const moduleId of Object.keys(course.modules)) {
      const module = course.modules[moduleId];
      for (const lessonId of Object.keys(module.lessons)) {
        const lesson = module.lessons[lessonId];
        if (foundCurrent) {
          nextLesson = { module, lesson };
          break;
        }
        if (lesson === currentLesson) {
          foundCurrent = true;
        }
      }
      if (nextLesson) break;
    }

    if (nextLesson) {
      setCurrentModule(nextLesson.module);
      setCurrentLesson(nextLesson.lesson);
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleExamAccess = () => {
    window.location = `/myquiz/${course.id}`;
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSubmitReview = () => {
    if (!userId || !rating || !comment) return;

    const review = {
      userId,
      userName: user.displayName,
      userPic: user.photoURL,
      rating,
      comment,
      date: new Date().toISOString(),
    };

    const reviewsRef = ref(database, `courses/${course.id}/reviews`);
    const newReviewRef = push(reviewsRef);
    set(newReviewRef, review);

    setReviews((prevReviews) => [...prevReviews, { id: newReviewRef.key, ...review }]);
    setRating(0);
    setComment('');
  };

  const handleEditReview = (review) => {
    setEditReview(review);
    setOpenEditDialog(true);
  };

  const handleUpdateReview = () => {
    if (!editReview) return;

    const reviewRef = ref(database, `courses/${course.id}/reviews/${editReview.id}`);
    update(reviewRef, { rating: editReview.rating, comment: editReview.comment }).then(() => {
      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review.id === editReview.id ? { ...review, rating: editReview.rating, comment: editReview.comment } : review
        )
      );
      setOpenEditDialog(false);
      setEditReview(null);
    });
  };

  const handleDeleteReview = (reviewId) => {
    const reviewRef = ref(database, `courses/${course.id}/reviews/${reviewId}`);
    remove(reviewRef).then(() => {
      setReviews((prevReviews) => prevReviews.filter((review) => review.id !== reviewId));
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth="lg">
      <Paper elevation={0} style={{ padding: '24px', marginTop: '24px' }}>
        <Typography variant="h4" gutterBottom>{course.title}</Typography>
        <div style={{ display: 'flex', marginBottom: '24px' }}>
          <div style={{ flex: 3 }}>
            {currentLesson && (
              <div>
                <ReactPlayer
                  url={currentLesson.url}
                  controls
                  playing
                  width="100%"
                  height="auto"
                  onEnded={handleLessonCompleted}
                  onDuration={handleDuration}
                />
                <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                  {currentLesson.title}
                </Typography>
                <div style={{ marginTop: '16px' }}>
                  <LinearProgress variant="determinate" value={progress} />
                  <Typography variant="body2" color="textSecondary">{Math.round(progress)}%</Typography>
                  <div style={{ flex: 1, marginLeft: '24px' }}>
                    {moduleQuizAvailable && (
                      <div style={{ marginTop: '16px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                       
                        >
                          Teste de disponível
                        </Button>
                      </div>
                    )}
                    {courseCompleted && (
                      <div style={{ marginTop: '16px' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleExamAccess}
                        >
                          Exame disponível
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="course details tabs">
          <Tab label="Sobre este curso" />
          <Tab label="Revisões" />
          <Tab label="Perguntas Frequentes" />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
          <Typography variant="body1">
            {course.description}
          </Typography>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Box>
            <Typography variant="h6" gutterBottom>Deixe uma avaliação</Typography>
            <Rating
              name="course-rating"
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
            />
            <TextField
              label="Comentário"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              style={{ marginTop: '16px' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitReview}
              style={{ marginTop: '16px' }}
            >
              Enviar
            </Button>
            <Typography variant="h6" gutterBottom style={{ marginTop: '32px' }}>Avaliações</Typography>
            {Array.isArray(reviews) && reviews.length > 0 ? (
              reviews.map((review, index) => (
                <Paper key={index} elevation={1} style={{ padding: '16px', marginBottom: '16px' }}>
                  <p><img src={review.userPic} style={{width:'10%', borderRadius:'100px'}}/>{review.userName}</p>
                  <Rating value={review.rating} readOnly />
                  <Typography variant="body2" color="textSecondary">
                    {new Date(review.date).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body1">
                    {review.comment}
                  </Typography>
                  {review.userId === userId && (
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditReview(review)}
                        style={{ marginTop: '8px', marginRight: '8px' }}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteReview(review.id)}
                        style={{ marginTop: '8px' }}
                      >
                        Eliminar
                      </Button>
                    </div>
                  )}
                </Paper>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">Sem avaliações ainda.</Typography>
            )}
          </Box>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          {/* Aqui você pode adicionar o código para exibir as perguntas frequentes */}
          PERGUNTAS FREQUENTES
        </TabPanel>
      </Paper>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Editar Avaliação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edite sua avaliação abaixo.
          </DialogContentText>
          <Rating
            name="edit-course-rating"
            value={editReview ? editReview.rating : 0}
            onChange={(event, newValue) => setEditReview({ ...editReview, rating: newValue })}
          />
          <TextField
            label="Comentário"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={editReview ? editReview.comment : ''}
            onChange={(event) => setEditReview({ ...editReview, comment: event.target.value })}
            style={{ marginTop: '16px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleUpdateReview} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default Player;
