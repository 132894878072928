import React, { useEffect, useState } from 'react';
import { ref, onValue } from "firebase/database";
import { auth, database } from '../fb';
import { signOut } from "firebase/auth";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [completedCourses, setCompletedCourses] = useState([]);
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(database, `users/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setUserData(data);
      });

      const coursesRef = ref(database, `users/${currentUser.uid}/courses`);
      onValue(coursesRef, (snapshot) => {
        const coursesData = snapshot.val();
        if (coursesData) {
          const completed = [];
          const ongoing = [];
          const certs = [];

          Object.keys(coursesData).forEach(key => {
            const course = coursesData[key].course;
            const progress = coursesData[key].progress;
            const certificate = coursesData[key].certificate;

            if (progress === 100) {
              completed.push({ id: key, ...course });
            } else {
              ongoing.push({ id: key, ...course, progress });
            }

            if (certificate) {
              certs.push({ id: key, ...course });
            }
          });

          setCompletedCourses(completed);
          setOngoingCourses(ongoing);
          setCertificates(certs);
        }
      });
    }
  }, [currentUser]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      window.location.href = '/login';
    }).catch((error) => {
      console.error("Error signing out: ", error);
    });
  };

  if (!currentUser) return <div>Por favor, faça login para ver seu perfil.</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Perfil</h1>
      {userData && (
        <div className="mb-8">
          <p><strong>Nome:</strong> {userData.displayName}</p>
          <p><strong>Email:</strong> {currentUser.email}</p>
          <button 
            onClick={handleLogout} 
            className="bg-red-600 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Logout
          </button>
        </div>
      )}
      <div>
        <h2 className="text-xl font-bold mb-4">Cursos em Andamento</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
          {ongoingCourses.length > 0 ? (
            ongoingCourses.map((course) => (
              <div key={course.id} className="border p-4 rounded shadow">
                <img src={course.coverImage} alt={course.title} className="w-full h-32 object-cover mb-4" />
                <h3 className="text-lg font-bold mb-2">{course.title}</h3>
                <p className="mb-2">{course.description}</p>
                <p className="font-bold">Progresso: {course.progress}%</p>
              </div>
            ))
          ) : (
            <p>Você não está inscrito em nenhum curso em andamento.</p>
          )}
        </div>
        
        <h2 className="text-xl font-bold mb-4">Cursos Concluídos</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
          {completedCourses.length > 0 ? (
            completedCourses.map((course) => (
              <div key={course.id} className="border p-4 rounded shadow">
                <img src={course.coverImage} alt={course.title} className="w-full h-32 object-cover mb-4" />
                <h3 className="text-lg font-bold mb-2">{course.title}</h3>
                <p className="mb-2">{course.description}</p>
              </div>
            ))
          ) : (
            <p>Você não concluiu nenhum curso.</p>
          )}
        </div>

        <h2 className="text-xl font-bold mb-4">Certificados Obtidos</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {certificates.length > 0 ? (
            certificates.map((course) => (
              <div key={course.id} className="border p-4 rounded shadow">
                <img src={course.coverImage} alt={course.title} className="w-full h-32 object-cover mb-4" />
                <h3 className="text-lg font-bold mb-2">{course.title}</h3>
                <p className="mb-2">{course.description}</p>
                <p className="font-bold text-green-600">Certificado Obtido</p>
              </div>
            ))
          ) : (
            <p>Você não obteve nenhum certificado.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
