import React, { useState } from 'react';
import { TextField, Button, IconButton, List, ListItem, ListItemText, Radio, FormControlLabel, RadioGroup } from '@mui/material';
import { ref, set } from 'firebase/database';
import { database } from '../fb';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';

const CreateQuiz = () => {
  const { id } = useParams();

  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [answers, setAnswers] = useState(['', '', '']);
  const [correctAnswer, setCorrectAnswer] = useState(0);

  const handleAddQuestion = () => {
    if (currentQuestion && answers.every(answer => answer)) {
      setQuestions([...questions, { question: currentQuestion, answers, correctAnswer }]);
      setCurrentQuestion('');
      setAnswers(['', '', '']);
      setCorrectAnswer(0);
    }
  };

  const handleSaveQuiz = async () => {
    const quizRef = ref(database, `courses/${id}/quiz`);
    await set(quizRef, { questions });
    alert('Quiz saved successfully!');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Exame</h1>
      <div className="mb-4">
        <TextField
          label="Pergunta 1"
          value={currentQuestion}
          onChange={(e) => setCurrentQuestion(e.target.value)}
          fullWidth
          className="mb-4"
        />
      </div>
      {answers.map((answer, index) => (
        <div key={index} className="mb-4">
          <TextField
            label={`Resposta ${index + 1}`}
            value={answer}
            onChange={(e) => {
              const newAnswers = [...answers];
              newAnswers[index] = e.target.value;
              setAnswers(newAnswers);
            }}
            fullWidth
            className={`mb-4 ${correctAnswer === index ? 'border-2 border-green-500' : ''}`}
          />
          <FormControlLabel
            control={
              <Radio
                checked={correctAnswer === index}
                onChange={() => setCorrectAnswer(index)}
                name="correctAnswer"
              />
            }
            label="Certa"
            className="mb-4"
          />
        </div>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddQuestion}
        startIcon={<AddIcon />}
        className="mb-4"
      >
        Adicionar questao
      </Button>
      <List className="mb-4">
        {questions.map((qa, index) => (
          <ListItem key={index} className="mb-2">
            <ListItemText
              primary={`Q: ${qa.question}`}
              secondary={qa.answers.map((answer, i) => (
                <div key={i} className={qa.correctAnswer === i ? 'text-green-500' : ''}>
                  {answer}
                </div>
              ))}
            />
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleSaveQuiz}
        className="mt-4">
        Salvar exame
      </Button>
    </div>
  );
};

export default CreateQuiz;
