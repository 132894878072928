import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ref, onValue, set } from 'firebase/database';
import { auth, database } from '../fb'; 
import ReactPlayer from 'react-player';
import { Tabs, Tab, Box, Typography, IconButton } from '@mui/material';
import { AccessTime, Dataset, Description, OndemandVideo, WorkspacePremium, People as PeopleIcon, Star as StarIcon } from '@mui/icons-material';

const CourseDetailPage = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('mpesa');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        const userSubscriptionRef = ref(database, `users/${user.uid}/courses/${id}`);
        onValue(userSubscriptionRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setIsEnrolled(true);
          }
        });
      }
    });

    const courseRef = ref(database, `courses/${id}`);
    onValue(courseRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCourse(data);
      }
    });
  }, [id]);

  const confirmSubscription = () => {
    if (!user) {
      console.error('Usuário não autenticado');
      window.location = '/login';
      return;
    }

    const userSubscriptionRef = ref(database, `users/${user.uid}/courses/${id}`);
    const courseSubscriptionRef = ref(database, `courses/${id}/students/${user.uid}`);

    const userCourseData = {
      course: course,
      completedLessons: false,
      exam: false,
      certificate: false,
      startDate: new Date().toISOString(),
      completed: false,
      phone: phoneNumber,
      paymentMethod: paymentMethod,
    };

    const courseUserData = {
      name: user.displayName,
      email: user.email,
      phone: phoneNumber,
      subscriptionDate: new Date().toISOString(),
      paymentMethod: paymentMethod,
    };

    Promise.all([
      set(userSubscriptionRef, userCourseData),
      set(courseSubscriptionRef, courseUserData)
    ]).then(() => {
      console.log('Inscrição confirmada e salva na base de dados.');
      setIsModalOpen(false);
      setIsEnrolled(true);
    }).catch((error) => {
      console.error('Erro ao salvar inscrição:', error.message);
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!course) {
    return <div>Carregando...</div>;
  }

  const numberOfModules = course.modules ? Object.keys(course.modules).length : 0;
  const numberOfStudents = course.students ? Object.keys(course.students).length : 0;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        <div className="lg:w-3/4 bg-white shadow-md rounded-lg p-6 mb-8">
          {course.trailer &&
            <ReactPlayer
              url={course.trailer}
              controls
              width="100%"
              height="auto"
              className="rounded-lg mb-4"
            />
          }
          <h1 className="text-3xl font-bold mb-4">{course.title}</h1>
          <Typography variant="body1" color="textSecondary" paragraph>
            {course.description}
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="course tabs">
            <Tab label="Sobre" />
            <Tab label="Conteúdo" />
            <Tab label="Requisitos" />
            <Tab label="Avaliações" />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <Box>
              <Typography variant="h6" className="mb-4">Sobre o curso</Typography>
              {course.description}
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Box>
              <Typography variant="h6" className="mb-4">Módulos</Typography>
              {course.modules && Object.keys(course.modules).map((moduleKey, index) => (
                <div key={index} className="mb-4">
                  <Typography variant="subtitle1" className="font-bold">{course.modules[moduleKey].title}</Typography>
                  <ul className="list-disc list-inside">
                    {course.modules[moduleKey].lessons && Object.keys(course.modules[moduleKey].lessons).map((lessonKey, lessonIndex) => (
                      <li key={lessonIndex} className="text-lg text-gray-700">
                        <a href={course.modules[moduleKey].lessons[lessonKey].url} target="_blank" rel="noopener noreferrer">
                          {course.modules[moduleKey].lessons[lessonKey].name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Box>
              <Typography variant="h6" className="mb-4">Requisitos</Typography>
              <ul className="list-disc list-inside mb-4">
                {course.requisitos && course.requisitos.map((item, index) => (
                  <li key={index} className="text-lg text-gray-700">{item}</li>
                ))}
              </ul>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Box>
              <Typography variant="h6" className="mb-4">Avaliações</Typography>
              {course.reviews && course.reviews.map((review, index) => (
                <Box key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
                  <Typography variant="subtitle2" className="font-bold text-gray-800">{review.user}</Typography>
                  <Box className="text-yellow-500 flex items-center">
                    {Array(review.rating).fill().map((_, i) => (
                      <StarIcon key={i} />
                    ))}
                  </Box>
                  <Typography variant="body2" className="text-gray-700">{review.comment}</Typography>
                </Box>
              ))}
            </Box>
          </TabPanel>
        </div>
        <div className="lg:w-1/4 bg-white shadow-md rounded-lg p-6 mb-8">
          <Box className="mb-4">
            <Typography variant="h5" className="font-bold">{course.title}</Typography>
            <Typography variant="body1" color="textSecondary" paragraph>{course.description}</Typography>
            <Box className="flex items-center mb-4">
              <PeopleIcon className="text-gray-600 mr-2" />
              <Typography variant="body1" color="textSecondary">{numberOfStudents} alunos</Typography>
            </Box>
            <Typography variant="h6" className="text-center mb-4">
              {course.price ?? 'Preço não disponível'} MT
            </Typography>
            {!isEnrolled ? (
              <Link to={`/checkout/${id}`} className="bg-green-600 text-white font-bold py-2 px-4 w-full text-center block">
                Inscrever-se
              </Link>
            ) : (
              <Link to={`/play/${id}`} className="bg-blue-600 text-white font-bold py-2 px-4 w-full text-center block">
                Acessar Curso
              </Link>
            )}
          </Box>
          <Box className="mb-4">
            <Typography variant="h6" className="font-bold">Inclui</Typography>
            <Box className="flex items-center mb-2">
              <Dataset className="text-gray-600 mr-2" />
              <Typography variant="body1" color="textSecondary">{numberOfModules} módulos</Typography>
            </Box>
            <Box className="flex items-center mb-2">
              <Description className="text-gray-600 mr-2" />
              <Typography variant="body1" color="textSecondary">{course.numFiles ?? 'Dados não disponíveis'} Ficheiros para download</Typography>
            </Box>
            <Box className="flex items-center mb-2">
              <WorkspacePremium className="text-gray-600 mr-2" />
              <Typography variant="body1" color="textSecondary">Certificado</Typography>
            </Box>
          </Box>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-1/2">
            <Typography variant="h6" className="font-bold mb-4">Confirmação de Inscrição</Typography>
            <Typography variant="body1" paragraph>
              Escolha o método de pagamento e insira o seu número de telefone para confirmar a inscrição.
            </Typography>
            <Box className="mb-4">
              <label className="flex items-center mb-2">
                <input
                  type="radio"
                  value="mpesa"
                  checked={paymentMethod === 'mpesa'}
                  onChange={() => setPaymentMethod('mpesa')}
                  className="mr-2"
                />
                Mpesa
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value="cartao"
                  checked={paymentMethod === 'cartao'}
                  onChange={() => setPaymentMethod('cartao')}
                  className="mr-2"
                />
                Cartão de Crédito
              </label>
            </Box>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Digite o seu número de telefone"
              className="w-full px-4 py-2 border rounded-lg mb-2 mt-2"
            />
            <Box className="flex justify-end mt-4">
              <button onClick={confirmSubscription} className="bg-green-600 text-white px-4 py-2 rounded-lg mr-2">Confirmar Inscrição</button>
              <button onClick={() => setIsModalOpen(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg">Cancelar</button>
            </Box>
          </div>
        </div>
      )}
    </div>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
 
export default CourseDetailPage;
