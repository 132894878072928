// pages/RegisterPage.js
import React from 'react';

const RegisterPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Register</h1>
      <form className="space-y-4">
        <input type="text" placeholder="Full Name" className="w-full p-2 border border-gray-300 rounded" />
        <input type="email" placeholder="Email" className="w-full p-2 border border-gray-300 rounded" />
        <input type="password" placeholder="Password" className="w-full p-2 border border-gray-300 rounded" />
        <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Register</button>
      </form>
    </div>
  );
}

export default RegisterPage;
