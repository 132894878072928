import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bg1 from '../images/bg1.jpg';
import bg2 from '../images/bg2.jpg';
import bg3 from '../images/bg3.jpg';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CoursesPage from './CoursesPage';

const HomePage = () => {
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  }
  return (
 <>
    <div>
      <div className="bg-gray-800 text-white p-8">
        <h1 className="text-4xl font-bold">APRENDA DE QUALQUER LUGAR</h1>
        <p className="mt-4 text-lg">Investa no seu perfil profissional com os nossos cursos e adquira um Certificado após conclusão</p>
      </div>
      <Slider {...settings} className="container mx-auto mt-8">
        <div className="relative">
          <img src={bg1} alt="Slide 1" className="w-full h-64 object-cover"/>
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white p-4">
            <h2 className="text-2xl font-bold text-center">
            </h2>
          </div>
        </div>
        <div className="relative">
          <img src={bg2} alt="Slide 2" className="w-full h-64 object-cover" />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white p-4">
            <h2 className="text-2xl font-bold text-center">
            </h2>
          </div>
        </div>
        <div className="relative">
          <img src={bg3} alt="Slide 3" className="w-full h-64 object-cover"/>
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white p-4">
            <h2 className="text-2xl font-bold text-center">
            </h2>
          </div>
        </div>
      </Slider>
    </div>
    <CoursesPage/></>
  );
};

export default HomePage;
