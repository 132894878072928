import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ref, get, update } from 'firebase/database';
import { auth, database } from '../fb';
import CertificateGenerator from './CertificateGenerator';

const MyQuiz = () => {
  const { id } = useParams();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('Usuário');
  const [courseName, setCourseName] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        setUserName(user.displayName);
      } else {
        window.location = '/login';
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId && id) {
      const courseRef = ref(database, `users/${userId}/courses/${id}/course`);
      get(courseRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const courseData = snapshot.val();
            setCourseName(courseData.name);
            setQuestions(courseData.quiz.questions);
          }
        })
        .catch((error) => {
          console.error('Error fetching course data:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userId, id]);

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers({
      ...answers,
      [questionIndex]: parseInt(answer),
    });
  };

  const calculateScore = () => {
    let correctAnswers = 0;
    questions.forEach((question, index) => {
      if (answers[index] === question.correctAnswer) {
        correctAnswers += 1;
      }
    });
    return (correctAnswers / questions.length) * 100;
  };

  const handleSubmitQuiz = () => {
    setOpenConfirmDialog(true);
  };

  const confirmSubmitQuiz = () => {
    setOpenConfirmDialog(false);
    const calculatedScore = calculateScore();
    setScore(calculatedScore);
    setQuizCompleted(true);

    const updates = {
      [`users/${userId}/courses/${id}/completedLessons`]: true,
    };

    if (calculatedScore >= 70) {
      updates[`users/${userId}/courses/${id}/exam`] = true;
      updates[`users/${userId}/courses/${id}/certificate`] = true;
    } else {
      alert('Você não passou no quiz. Tente novamente.');
    }

    update(ref(database), updates).catch((error) => {
      console.error('Error updating quiz data:', error);
    });
  };

  const handleRetakeQuiz = () => {
    setAnswers({});
    setQuizCompleted(false);
    setScore(0);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex items-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  if (quizCompleted) {
    const isPassed = score >= 70;
    return (
      <div className="container mx-auto p-8">
        <h1 className="text-4xl font-bold mb-4">Exame Concluído!</h1>
        <h2 className="text-2xl mb-4">Sua Pontuação: {score}%</h2>
        <h3 className={`text-xl mb-4 ${isPassed ? 'text-green-500' : 'text-red-500'}`}>
          {isPassed ? 'Parabéns! Você passou no quiz.' : 'Desculpe, você não passou no quiz.'}
        </h3>
        {isPassed && (
          <CertificateGenerator
            name={userName}
            course={courseName}
            date={new Date().toLocaleDateString('pt-BR')}
            issuer="Connection Mozambique"
          />
        )}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={isPassed ? () => alert('Quiz finalizado!') : handleRetakeQuiz}
        >
          {isPassed ? 'Finalizar' : 'Refazer Quiz'}
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-4">Exame</h1>
      <div className="w-full bg-gray-200 rounded-full">
        <div
          className="bg-blue-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
          style={{ width: `${(Object.keys(answers).length / questions.length) * 100}%` }}
        >
          {(Object.keys(answers).length / questions.length) * 100}%
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitQuiz();
        }}
      >
        {questions.map((question, index) => (
          <div key={index} className="mb-4">
            <h2 className="text-2xl mb-2">{question.question}</h2>
            <div className="flex flex-col">
              {question.answers.map((answer, ansIndex) => (
                <label key={ansIndex} className="inline-flex items-center mt-2">
                  <input
                    type="radio"
                    name={`question-${index}`}
                    value={ansIndex.toString()}
                    checked={answers[index] === ansIndex}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                    className="form-radio"
                  />
                  <span className="ml-2">{answer}</span>
                </label>
              ))}
            </div>
          </div>
        ))}
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          disabled={Object.keys(answers).length !== questions.length}
        >
          Enviar Respostas
        </button>
      </form>
      <div
        className={`fixed z-10 inset-0 overflow-y-auto ${openConfirmDialog ? '' : 'hidden'}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Confirmar Submissão
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Tem certeza de que deseja enviar suas respostas?</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={confirmSubmitQuiz}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => setOpenConfirmDialog(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyQuiz;
