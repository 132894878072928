// src/components/AdminLayout.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import '../style/AdminLayout.css';
import NavigationAdmin from './NavigationAdmin';

const AdminLayout = ({ children }) => {
  return (
    <div >
      <NavigationAdmin/>
      <div className="admin-content">
        {children}
      </div>
    </div>
  );
};

export default AdminLayout;
