// components/ListView.js
import React from 'react';
import CourseCard from './CourseCard';

const ListView = ({ courses, userId }) => {
  return (
    <div className="flex flex-col space-y-4">
      {courses.map((course) => (
        <div key={course.id} className="border rounded-lg p-4 shadow-md">
          <CourseCard course={course} userId={userId} />
        </div>
      ))}
    </div>
  );
}

export default ListView;