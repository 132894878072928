import React from 'react';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Quiz } from '@mui/icons-material';

const CourseCard = ({ course, userId }) => {

  // Calculate total course hours based on lesson durations
  let totalHours = 0;
  if (course.modules) {
    Object.values(course.modules).forEach(module => {
      Object.values(module.lessons).forEach(lesson => {
        if (typeof lesson.duration === 'number') {
          totalHours += lesson.duration;
        }
      });
    });
  }

  // Format total hours to one decimal place
  const formattedHours = totalHours.toFixed(1);
  const courseHours = totalHours > 0 ? `${formattedHours} horas` : 'Horas não disponíveis';


  const isEnrolled = course.students && course.students[userId];
  const progress = isEnrolled ? course.students[userId].progress : 0; // Assuming the progress is stored in the student's data

  // Determine the URL based on enrollment status
  const courseUrl = isEnrolled ? `/play/${course.id}` : `/course/${course.id}`;

  // Use a default value if hours is not available

  return (
    <Link to={courseUrl} className="block hover:shadow-lg transition-shadow duration-300">
      <div className="bg-white shadow-md rounded-lg overflow-hidden relative">
        {course.coverImage && (
          <img src={course.coverImage} alt={course.title} className="w-full h-32 sm:h-48 object-cover" />
        )}
        {isEnrolled && (
          <div className="absolute top-2 right-2 flex items-center bg-green-500 text-white text-xs px-2 py-1 rounded">
            <CheckCircleIcon fontSize="small" className="mr-1" />
            Inscrito
          </div>
        )}
        <div className="p-4">
          <h2 className="text-lg font-bold">{course.title}</h2>
          <p className="text-gray-600 mt-2">{course.description}</p>
          <div className="flex items-center mt-4">
            <AccessTimeIcon className="text-gray-600 mr-2" />
            <span>{courseHours}</span>
          </div>
          <div className="flex items-center mt-2">
            <PeopleIcon className="text-gray-600 mr-2" />
            <span>{course.students ? `${Object.keys(course.students).length} alunos` : '0'}</span>
          </div>
           <div className="flex items-center mt-2">
            <Quiz className="text-gray-600 mr-2" />
            <span>Quiz disponivel</span>
          </div>
          <div className="flex items-center mt-2">
            <AttachMoneyIcon className="text-gray-600 mr-2" />
            <span>{course.price} MT</span>
          </div>
          {isEnrolled && (
            <div className="mt-4">
              <LinearProgress variant="determinate" style={{height:'6px'}} value={progress} />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default CourseCard;
