import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ref, set, onValue } from 'firebase/database';
import { auth, database } from '../fb';
import { Button, CircularProgress, Radio, RadioGroup, FormControlLabel, TextField, Snackbar, Alert } from '@mui/material';

const CheckoutPage = () => {
  const { id } = useParams();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('mpesa');
  const [course, setCourse] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: '' });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      }
    });

    const courseRef = ref(database, `courses/${id}`);
    onValue(courseRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCourse(data);
      }
    });
  }, [id]);

  const confirmSubscription = () => {
    if (!user) {
      console.error('Usuário não autenticado');
      window.location = '/login';
      return;
    }

    setIsLoading(true);

    const wallet_id = 582314;
    const ENDPOINT_URL = `https://e2payments.explicador.co.mz/v1/c2b/mpesa-payment/${wallet_id}`;

    const payloadC2b = {
      "client_id": "9c8f1179-66ce-44bb-999d-e292f62e1860",
      "amount": String(course.price),
      "phone": String(phoneNumber),
      "reference": "PagamentoDeCursoMjacademy",
    };

    const header = {
      Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5YzhmMTE3OS02NmNlLTQ0YmItOTk5ZC1lMjkyZjYyZTE4NjAiLCJqdGkiOiJjYzc2MzM5YTEzNGQzMDViNjk0NzA0Njk2Y2I1NWUzMDkwNmNhM2ExOGI5ZGQ3NDkxMTY5M2MxNzQ1Y2I0YTAzNDBmMjJmODI3YzRjNWRhMyIsImlhdCI6MTcyMTM5ODM3NS4yNDgzNDMsIm5iZiI6MTcyMTM5ODM3NS4yNDgzNDYsImV4cCI6MTc1MjkzNDM3NS4yNDAzMTMsInN1YiI6IiIsInNjb3BlcyI6W119.y5RB9XRrmiTtdwIX9WMX1G77FJiuzJKQWQv-8kytAnJwhDwrGA5UU6iQ_gMJeJsBo8n5S3ER4zfFwqu7KvIJBdVQnb74P15j3BTkuz5EtvaQJgQuwv3kM8PZQ_79hY7ngkmQms0Mc83PPI-PMx4huLB4I-5OfFfdIW716t4Htxz8T7BmDS-XsPcqBjRrrPapASEol4ZFR3T8pJ4eznTPZBg21-QSKt4w2SseVSFgWEk272kufzSHnbSCddFDnMgawMP3Vu8boAb53tgZuDN-UfKg32xHdpMHozaj31MfPaXX_Reyn7qZuFXf37dJgOuz4ASkWI_ilkH_SdlZBeZPPdOEc-a8x0cN38KjoO4xrhDMrmMd9Z0YtFcVYyRodZv0X2daolNNhSNkUW50ct_KrZ0JApcdFpJUMV9fvADZckcbiPj6l30zw5i8wPiQ9vgCIMGrnTRL3UaAgGqvDpwpRejhZ3djlsEk6dvbDaPsTtgvVazYBJSApI39Bsv1_srFdjgaOs7Rulos31vUZT07oJjTWQo_0jHGMeLnryCjU7YDV0NoW4VjzeEdYctZlufCIleTNReg2-PfvY5OnW5IFpB-0QM7vw5cBSsqn3L7k9V9EnZ-wpMtiFgZDOGH_y1BdHCrb44INlSmwmr2UJbDpTaRz2oQBFpjDsLvNQftf-g",
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    axios.post(ENDPOINT_URL, payloadC2b, { headers: header }).then(function (response) {
      console.log(response.data);
      const userSubscriptionRef = ref(database, `users/${user.uid}/courses/${id}`);
      const courseSubscriptionRef = ref(database, `courses/${id}/students/${user.uid}`);

      const userCourseData = {
        course: course,
        completedLessons: false,
        exam: false,
        certificate: false,
        startDate: new Date().toISOString(),
        completed: false,
        phone: phoneNumber,
        paymentMethod: paymentMethod,
      };

      const courseUserData = {
        name: user.displayName,
        email: user.email,
        phone: phoneNumber,
        subscriptionDate: new Date().toISOString(),
        paymentMethod: paymentMethod,
      };

      Promise.all([
        set(userSubscriptionRef, userCourseData),
        set(courseSubscriptionRef, courseUserData),
      ]).then(() => {
        console.log('Inscrição confirmada e salva na base de dados.');
        setNotification({ open: true, message: 'Inscrição confirmada com sucesso!', severity: 'success' });
        setIsLoading(false);
        window.location = `/play/${id}`;
      }).catch((error) => {
        console.error('Erro ao salvar inscrição:', error.message);
        setNotification({ open: true, message: 'Erro ao salvar inscrição. Por favor, tente novamente.', severity: 'error' });
        setIsLoading(false); // Garantir que o botão fique disponível
      });
    }).catch(function (err) {
      console.error('A transação falhou', err);
      setNotification({ open: true, message: 'A transação falhou. Por favor, tente novamente.', severity: 'error' });
      setIsLoading(false); // Garantir que o botão fique disponível
    });
  };

  const handleClose = () => {
    setNotification({ ...notification, open: false });
  };

  if (!course) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Inscrever-se no Curso: {course.title}</h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <p>Metodo de pagamento:</p>
        <RadioGroup
          row
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          <FormControlLabel value="mpesa" control={<Radio />} label="MPesa" />
          <FormControlLabel value="emola" control={<Radio />} label="eMola" />
        </RadioGroup>
        <TextField
          label="Número de Celular"
          variant="outlined"
          fullWidth
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="mt-4"
        />
        <div className="flex justify-end mt-4">
          <Button
            onClick={confirmSubscription}
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={24} />}
          >
            {isLoading ? 'Processando...' : 'Confirmar Inscrição'}
          </Button>
          <Button
            onClick={() => window.location = `/course/${id}`}
            variant="outlined"
            color="secondary"
            className="ml-2"
          >
            Cancelar
          </Button>
        </div>
      </div>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CheckoutPage;
