// src/components/CourseDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { database } from '../fb';
import { ref as dbRef, update, remove, onValue } from 'firebase/database';
import QuizModal from '../components/QuizModal';

const ViewCourse = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [published, setPublished] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState('');

  useEffect(() => {
    // Busca os dados do curso do Firebase
    const courseRef = dbRef(database, `courses/${id}`);
    onValue(courseRef, (snapshot) => {
      const data = snapshot.val();
      setCourse(data);
      setTitle(data.title);
      setDescription(data.description);
      setPrice(data.price);
      setCoverImage(data.coverImage);
      setPublished(data.published);
    });

    return () => courseRef.off(); // Cleanup on unmount
  }, [id]);

  const handleSave = () => {
    const courseRef = dbRef(database, `courses/${id}`);
    update(courseRef, { title, description, price, coverImage });
    setEditMode(false);
  };

  const handleDelete = () => {
    const courseRef = dbRef(database, `courses/${id}`);
    remove(courseRef);
    window.location = 'coursesList';
  };

  const handleCancelPublication = () => {
    const courseRef = dbRef(database, `courses/${id}`);
    update(courseRef, { published: false });
    setPublished(false);
  };

  const handleAddQuizToModule = (moduleKey) => {
    setSelectedModule(moduleKey);
    setQuizModalOpen(true);
  };

  const handleSaveQuiz = (quiz) => {
    const moduleRef = dbRef(database, `courses/${id}/modules/${selectedModule}/quiz`);
    update(moduleRef, { questions: quiz });
  };

  if (!course) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">{editMode ? 'Editando Curso' : course.title}</h2>
      {editMode ? (
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="title">
              Título
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="description">
              Descrição
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="price">
              Preço
            </label>
            <input
              type="text"
              id="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="coverImage">
              Imagem de Capa
            </label>
            <input
              type="text"
              id="coverImage"
              value={coverImage}
              onChange={(e) => setCoverImage(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <button
            onClick={handleSave}
            className="bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Salvar
          </button>
          <button
            onClick={() => setEditMode(false)}
            className="bg-gray-600 text-white font-bold py-2 px-4 rounded"
          >
            Cancelar
          </button>
        </div>
      ) : (
        <div>
          <p><strong>Descrição:</strong> {course.description}</p>
          <p><strong>Preço:</strong> {course.price}</p>
          <p><strong>Publicado:</strong> {course.published ? 'Sim' : 'Não'}</p>
          <p><strong>Imagem de Capa:</strong> <a href={course.coverImage} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">{course.coverImage}</a></p>
          <h3 className="text-xl font-bold mt-4">Módulos</h3>
          {course.modules && Object.keys(course.modules).map((moduleKey) => (
            <div key={moduleKey}>
              <h4 className="font-bold">{course.modules[moduleKey].title}</h4>
              {course.modules[moduleKey].lessons && Object.keys(course.modules[moduleKey].lessons).map((lessonKey) => (
                <div key={lessonKey}>
                  <p><strong>{course.modules[moduleKey].lessons[lessonKey].name}</strong></p>
                  <p>Duração: {course.modules[moduleKey].lessons[lessonKey].duration} minutos</p>
                  {course.modules[moduleKey].lessons[lessonKey].type === 'video' && (
                    <p>URL do Vídeo: <a href={course.modules[moduleKey].lessons[lessonKey].url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">{course.modules[moduleKey].lessons[lessonKey].url}</a></p>
                  )}
                </div>
              ))}
              <div>
                <h4 className="font-bold">Quiz</h4>
                {course.modules[moduleKey].quiz ? (
                  <div>
                    {course.modules[moduleKey].quiz.questions.map((question, index) => (
                      <div key={index}>
                        <p><strong>{question.question}</strong></p>
                        <ul>
                          {question.answers.map((answer, i) => (
                            <li key={i}>{answer}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                ) : (
                  <button
                    onClick={() => handleAddQuizToModule(moduleKey)}
                    className="bg-green-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Adicionar Quiz
                  </button>
                )}
              </div>
            </div>
          ))}
          <div>
            <h3 className="text-xl font-bold">Quiz Geral</h3>
            {course.quiz && course.quiz.questions.map((question, index) => (
              <div key={index}>
                <p><strong>{question.question}</strong></p>
                <ul>
                  {question.answers.map((answer, i) => (
                    <li key={i}>{answer}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <button
            onClick={() => setEditMode(true)}
            className="bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Editar
          </button>
          <button
            onClick={handleDelete}
            className="bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Eliminar
          </button>
          {published && (
            <button
              onClick={handleCancelPublication}
              className="bg-yellow-600 text-white font-bold py-2 px-4 rounded"
            >
              Cancelar Publicação
            </button>
          )}
        </div>
      )}

      <QuizModal
        isOpen={quizModalOpen}
        onClose={() => setQuizModalOpen(false)}
        onSave={handleSaveQuiz}
      />
    </div>
  );
};

export default ViewCourse;
