import React, { useEffect, useState } from 'react';
import { ref, onValue } from "firebase/database";
import { auth, database } from '../fb';
import CourseCard from '../components/CourseCard';

const MyCoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const currentUser = auth.currentUser;



  useEffect(() => {
    if (currentUser) {
      const userCoursesRef = ref(database, `users/${currentUser.uid}/courses`);
      onValue(userCoursesRef, (snapshot) => {
        const userCoursesData = snapshot.val();
        if (userCoursesData) {
          // Converte o objeto de cursos do usuário em um array de IDs de cursos
          const userCourseIds = Object.keys(userCoursesData);

          // Pega os dados dos cursos a partir dos IDs
          const coursesRef = ref(database, 'courses');
          onValue(coursesRef, (coursesSnapshot) => {
            const allCoursesData = coursesSnapshot.val();
            if (allCoursesData) {
              // Filtra os cursos que o usuário está inscrito
              const userCourses = userCourseIds.map(courseId => ({
                id: courseId,
                ...allCoursesData[courseId]
              }));
              setCourses(userCourses);
              console.log(userCourses)
            }
          });
        }
      });
    }
  }, [currentUser]);

  if (!currentUser) {
    return <div className="container mx-auto px-4 py-8">Você precisa estar logado para ver seus cursos.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Meu Aprendizado</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {courses.map((course) => (
          <CourseCard key={course.id} course={course} userId={currentUser.uid} />
        ))}
      </div>
    </div>
  );
}

export default MyCoursesPage;
