import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Search, AccountCircle, Subscriptions, FavoriteOutlined, PeopleAltOutlined } from '@mui/icons-material';
import { auth, database } from '../fb';
import { ref as dbRef, onValue } from 'firebase/database';
import logo from '../images/logo.png';

const NavigationMenu = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const coursesRef = dbRef(database, 'courses');
    onValue(coursesRef, (snapshot) => {
      const data = snapshot.val();
      setCourses(Object.values(data));
    });
  }, []);

  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const filteredSuggestions = courses.filter(course =>
        course.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [searchQuery, courses]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim() !== '') {
    window.location=`/search?query=${searchQuery}`;
    }
  };

  const handleSuggestionClick = (courseId) => {
    window.location=`/course/${courseId}`;
    setSearchQuery('');
    setSuggestions([]);
  };

  return (
    <header className="bg-white shadow-md p-4 flex flex-wrap items-center justify-between">
      <div className="flex items-center space-x-4">
        <Link to="/" className="text-2xl font-bold text-gray-800">
          <img src={logo} alt="logo" style={{ width: '90px' }} />
        </Link>
      </div>
      <div className="relative flex-grow flex items-center bg-gray-100 rounded-full px-4 py-2 mt-4 md:mt-0 md:ml-4 md:mr-4"
        style={{ border: '1px solid #333' }}
      >
        <Search className="text-gray-600" />
        <input
          type="text"
          placeholder="Pesquisar curso"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="bg-transparent outline-none ml-2 w-full"
        />
        {suggestions.length > 0 && (
          <ul className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10 mt-1">
            {suggestions.map(suggestion => (
              <li
                key={suggestion.id}
                onClick={() => handleSuggestionClick(suggestion.id)}
                className="cursor-pointer p-2 hover:bg-gray-200"
              >
                {suggestion.title}
              </li>
            ))}
          </ul>
        )}
      </div>
      <nav className="flex flex-wrap items-center space-x-4 mt-4 md:mt-0">
        <Link to="/courses" className="text-gray-600 hover:text-gray-800 flex items-center">
          <Subscriptions fontSize="large" />
          <span className="hidden md:inline ml-2">Cursos</span>
        </Link>
        <Link to="/mycourses" className="text-gray-600 hover:text-gray-800 flex items-center">
          <FavoriteOutlined fontSize="large" />
          <span className="hidden md:inline ml-2">Meu aprendizado</span>
        </Link>
        <Link to="/community" className="text-gray-600 hover:text-gray-800 flex items-center">
          <PeopleAltOutlined fontSize="large" />
          <span className="hidden md:inline ml-2">Comunidade</span>
        </Link>
      </nav>
      <div className="flex items-center space-x-4 mt-4 md:mt-0">
        <Link to={currentUser ? "/profile" : "/login"} className="text-gray-600 hover:text-gray-800">
          <AccountCircle fontSize="large" />
        </Link>
      </div>
    </header>
  );
}

export default NavigationMenu;
