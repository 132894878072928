import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListSubheader, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


const Playlist = ({ course, onLessonChange }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedLessonContent, setSelectedLessonContent] = useState('');

  const handleOpenModal = (content) => {
    setSelectedLessonContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (!course || !course.modules) {
    return <div>Loading...</div>;
  }

  return (
    <List
      component="nav"
      subheader={<ListSubheader component="div">Conteúdo do Curso</ListSubheader>}
      className="course-modules"
    >
      {Object.keys(course.modules).map((moduleId) => {
        const module = course.modules[moduleId];
        const hasQuiz = module.quiz;
        return (
          <div key={moduleId}>
            <ListSubheader><b>Modulo: </b>{module.title}</ListSubheader>
            <List component="div" disablePadding>
              {module.aulaTexto && (
                <a
                  style={{textDecoration:'underline', color:'blue', fontWeight:'bold', cursor: 'pointer'}}
                  onClick={() => handleOpenModal(module.aulaTexto)}
                >
                  Visualizar Aula
                </a>
              )}
              {Object.keys(module.lessons).map((lessonId) => {
                const lesson = module.lessons[lessonId];
                return (
                  <ListItem
                    button
                    key={lessonId}
                    onClick={() => onLessonChange(moduleId, lesson)}
                  >
                    <IconButton>
                      <PlayArrowIcon />
                    </IconButton>
                    <ListItemText primary={lesson.name} />
                  </ListItem>
                );
              })}
              {hasQuiz && (
                <ListItem>
                  <Button variant="contained" href={`/modulequiz/${course.id}/${module.title}`} color="primary">
                    Realizar Quiz
                  </Button>
                </ListItem>
              )}
            </List>
          </div>
        );
      })}
      
      {/* Modal para exibir o conteúdo da aula */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Conteúdo da Aula</DialogTitle>
        <DialogContent dividers>
          <div className="card-text" dangerouslySetInnerHTML={{ __html: selectedLessonContent }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </List>
  );
};


export default Playlist