import React, { useEffect, useState } from 'react';
import Player from './Player';
import Playlist from '../components/Playlist';
import { onValue, ref } from 'firebase/database';
import { database } from '../fb';
import { useParams } from 'react-router-dom';

const CoursePlayer = () => {
  const { id } = useParams();
  const [course, setCourse] = useState({});
  const [currentLessonIndex, setCurrentLessonIndex] = useState(null);

  useEffect(() => {
    const coursesRef = ref(database, 'courses/' + id);
    onValue(coursesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const courseData = {
          id: id,
          ...data
        };
        setCourse(courseData);
      }
    });
  }, [id]);

  const handleLessonChange = (moduleId, lesson) => {
    setCurrentLessonIndex({ moduleId, lesson });
  };

  return (
    <div className="container mx-auto mt-6">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="col-span-1 md:col-span-2 bg-white shadow-md rounded-lg p-4">
        <Player course={course} currentLessonIndex={currentLessonIndex} />
      </div>
      <div className="col-span-1 bg-white shadow-md rounded-lg p-4">
        <Playlist course={course} onLessonChange={handleLessonChange} />
      </div>
    </div>
  </div>
  );
};

export default CoursePlayer;
