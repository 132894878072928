import React, { useState } from 'react'
import { FaGoogle, FaFacebook } from 'react-icons/fa'
import { signInWithPopup } from 'firebase/auth'
import { auth, googleProvider } from '../fb'

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider)
      window.location = '/'
    } catch (error) {
      setError('Erro ao fazer login com Google: ' + error.message)
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Aqui você pode adicionar a lógica para autenticar com email e senha
    // Se houver um erro, você pode usar setError para exibir a mensagem de erro
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Login</h1>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setError('')}>
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 5.652a.5.5 0 00-.707 0L10 9.293 6.354 5.646a.5.5 0 00-.707.707L9.293 10l-3.646 3.647a.5.5 0 00.707.707L10 10.707l3.646 3.647a.5.5 0 00.707-.707L10.707 10l3.647-3.646a.5.5 0 000-.707z" />
            </svg>
          </span>
        </div>
      )}
      <form className="space-y-4" onSubmit={handleFormSubmit}>
        <input 
          type="email" 
          placeholder="Email" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded" 
        />
        <input 
          type="password" 
          placeholder="Password" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded" 
        />
        <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Login</button>
      </form>
      <div className="flex items-center justify-center mt-4 space-x-4">
        <button
          onClick={handleGoogleSignIn}
          className="flex items-center justify-center p-2 border border-gray-300 rounded-full"
        >
          <FaGoogle className="text-red-500" size={24} />
        </button>
        <button className="flex items-center justify-center p-2 border border-gray-300 rounded-full">
          <FaFacebook className="text-blue-500" size={24} />
        </button>
      </div>
    </div>
  );
}

export default LoginPage;
